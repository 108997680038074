import { GraphQLClient } from 'graphql-request';

const client = new GraphQLClient(
  process.env.NODE_ENV === 'production'
  ? 'https://api3.guiapass.com/graphql'
  : 'http://localhost:5100/graphql'
);

export default function Api(options = {}) {
  if (options.authorized === true) {
    if (typeof window === 'undefined') return false;
    const user = JSON.parse(window.localStorage.getItem('userAffiliate'));
    if (!user || !user.token) return false;
    client.setHeader('authorization', `Bearer ${user.token}`);
  }

  // Add error handling to the client
  const wrappedClient = {
    ...client,
    request: async (...args) => {
      try {
        return await client.request(...args);
      } catch (error) {
        if (error.response?.status === 401) {
          const token = window.localStorage.getItem('userAffiliate');

          if (token) {
            window.localStorage.removeItem('userAffiliate');
            window.location.href = '/login';
          }
        }
        throw error; // Re-throw the error to be handled by the caller
      }
    }
  };

  return wrappedClient;
}

import Api from './Api';

export function getOrders() {
  const exec = `
    query affiliateOrders {
      affiliateOrders {
        id
        total
        comission
        approved
        checkin
        product {
          id
          destination
          name
        }
        options {
          amount
          price
          affiliateComission
          option {
            id
            name
          }
        }
      }
    }
  `;

  return {
    fetcher: Api({ authorized: true }).request(exec)
  };
}

import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import InputLabel from '@material-ui/core/InputLabel';
import InputAdornment from '@material-ui/core/InputAdornment';
import FormControl from '@material-ui/core/FormControl';
import Divider from '@material-ui/core/Divider';
import Icon from '@material-ui/core/Icon';
import Button from '@material-ui/core/Button';

import User from './UserContext';
import Alert from '../util/Alerts';

const useStyles = makeStyles((theme) => ({
  root: {
    margin: "auto",
    minWidth: "200px",
    maxWidth: "500px",
  },
  paper: {
    padding: theme.spacing(2),
  },
}));

export default function InvitationPage() {
  const classes = useStyles();
  const { user } = React.useContext(User);
  const [notice, setNotice] = React.useState();

  const textAreaRef = React.useRef(null);

  function copyToClipboard(e) {
    textAreaRef.current.select();
    document.execCommand('copy');

    setNotice('Link copiado');
    e.target.focus();
  };

  if (!user) return <></>;

  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <Typography variant="h4" gutterBottom>
          Convites
        </Typography>

        <Typography variant="body1" gutterBottom>
          Convide seus amigos e acompanhe por aqui os resultados de suas indicações.
        </Typography>

        <Divider />
        <br />

        <Alert message={notice} onClose={() => { setNotice(null) }} />

        <FormControl fullWidth variant="outlined">
          <InputLabel htmlFor="standard-adornment-password">Link de convite</InputLabel>
          <OutlinedInput
            inputRef={textAreaRef}
            readOnly
            labelWidth={110}
            value={`https://afiliado.guiapass.com/signup?i=${user.id}`}
            onFocus={copyToClipboard}
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  onClick={copyToClipboard}
                  onMouseDown={copyToClipboard}
                >
                  <Icon>file_copy</Icon>
                </IconButton>
              </InputAdornment>
            }
          />

          <br />

          <Button
            color="primary"
            variant="contained"
            href={`whatsapp://send?text=Ol%C3%A1%2C%20use%20este%20link%20para%20se%20registrar%20como%20afiliado%20GuiaPass%20e%20conhecer%20todas%20as%20vantagens%3A%20https%3A%2F%2Fafiliado.guiapass.com%2Fsignup%3Fi%3D${user.id}`}
            data-action="share/whatsapp/share"
            target="_blank"
            startIcon={<Icon>share</Icon>}
          >
            Convidar por WhatsApp
          </Button>
        </FormControl>
      </Paper>
    </div>
  );
}

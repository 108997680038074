import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useMediaQuery } from '@material-ui/core';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import Chip from '@material-ui/core/Chip';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import { getOrders } from '../api/Order';
import User from './UserContext';

const useStyles = makeStyles((theme) => ({
  root: {
    margin: "auto",
    minWidth: "200px",
    maxWidth: "500px",
  },
  paper: {
    padding: theme.spacing(2),
  },
  order: {
    padding: theme.spacing(2, 0),
    borderBottom: `1px solid ${theme.palette.divider}`,
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",

    "&:last-child": {
      borderBottom: "none",
    }
  },
  empty: {
    marginTop: theme.spacing(2),
    color: theme.palette.text.secondary,
  }
}));

export default function OrdersPage() {
  const classes = useStyles();
  const { user } = React.useContext(User);
  const isMobile = useMediaQuery('(max-width:600px)');
  const [orders, setOrders] = React.useState();
  const [pendingOrders, setPendingOrders] = React.useState();
  const [total, setTotal] = React.useState(0);
  const [pendingTotal, setPendingTotal] = React.useState(0);
  const [pendingComission, setPendingComission] = React.useState(0);
  const [approvedComission, setApprovedComission] = React.useState(0);
  const [orderDetails, setOrderDetails] = React.useState(null);

  const fetchOrders = () => {
    const { fetcher } = getOrders();

    fetcher.then((res) => {
      const approvedOrders = res.affiliateOrders.filter((order) => order.approved);
      const pendingOrders = res.affiliateOrders.filter((order) => !order.approved);
      const total = approvedOrders.reduce((acc, order) => acc + order.total, 0);
      const pendingTotal = pendingOrders.reduce((acc, order) => acc + order.total, 0);
      const pendingComission = pendingOrders.reduce((acc, order) => acc + order.comission, 0);
      const approvedComission = approvedOrders.reduce((acc, order) => acc + order.comission, 0);

      setOrders(approvedOrders);
      setPendingOrders(pendingOrders);
      setTotal(total);
      setPendingTotal(pendingTotal);
      setPendingComission(pendingComission);
      setApprovedComission(approvedComission);
    });
  };

  const formatCurrency = (value) => {
    return (value / 100).toFixed(2).replace('.', ',');
  };

  const formatCheckin = (checkin) => {
    return new Intl.DateTimeFormat('pt-BR').format(new Date(checkin));
  };

  const handleOrderDetails = (order) => {
    setOrderDetails(order);
  };

  const orderItem = (order) => (
    <div key={order.id} className={classes.order}>
      <div>
        <Typography variant="body1" gutterBottom>Checkin: {formatCheckin(order.checkin)}</Typography>
        <Typography variant="body1" gutterBottom>Total: R$ {formatCurrency(order.total)}</Typography>
        <Typography variant="body1" gutterBottom>Comissão: R$ {formatCurrency(order.comission)}</Typography>
        <Typography variant="body1" gutterBottom component="div">
          {order.approved ? (
            <Chip label="Aprovado" color="primary" size="small" />
          ) : (
            <Chip label="Pendente" color="default" size="small" />
          )}
        </Typography>
      </div>
      <div>
        <Button variant="contained" color="secondary" onClick={() => handleOrderDetails(order)}>
          Ver detalhes
        </Button>
      </div>
    </div>
  );

  React.useEffect(() => {
    fetchOrders();
  }, []);

  if (!user || !orders || !pendingOrders) return <></>;

  return (
    <>
      <div className={classes.root}>
        <Paper className={classes.paper}>
          <Typography variant="h4" gutterBottom>
            Total de vendas
          </Typography>

          <Typography variant="body1">
            <b>Aprovadas:</b> R$ {formatCurrency(total)} (R$ {formatCurrency(approvedComission)} de comissão)
            <br />
            <b>Pendentes:</b> R$ {formatCurrency(pendingTotal)} (R$ {formatCurrency(pendingComission)} de comissão)
          </Typography>

          <br />
          <Divider />
          <br />

          <Typography variant="h5">
            Vendas aprovadas
          </Typography>

          { orders && orders.length === 0 && (
            <Typography variant="body1" gutterBottom className={classes.empty}>
              Nenhuma venda aprovada ainda.
            </Typography>
          )}

          <br />

          { orders && orders.map((order) => orderItem(order)) }

          <br />

          <Typography variant="h5" gutterBottom>
            Vendas pendentes
          </Typography>

          { pendingOrders && pendingOrders.length === 0 && (
            <Typography variant="body1" gutterBottom className={classes.empty}>
              Nenhuma venda pendente ainda.
            </Typography>
          )}

          { pendingOrders && pendingOrders.map((order) => orderItem(order)) }
        </Paper>
      </div>

      { orderDetails && (
        <Dialog open={!!orderDetails} onClose={() => setOrderDetails(null)} fullScreen={isMobile}>
          <DialogTitle>Detalhes da venda</DialogTitle>

          <DialogContent>
            <Typography variant="body1" gutterBottom>Checkin: {formatCheckin(orderDetails.checkin)}</Typography>
            <Typography variant="body1" gutterBottom>Total: R$ {formatCurrency(orderDetails.total)}</Typography>
            <Typography variant="body1" gutterBottom>Comissão: R$ {formatCurrency(orderDetails.comission)}</Typography>
            <Typography variant="body1" gutterBottom component="div">
              {orderDetails.approved ? (
                <Chip label="Aprovado" color="primary" size="small" />
              ) : (
                <Chip label="Pendente" color="default" size="small"/>
              )}
            </Typography>

            <Divider style={{ marginTop: 16 }} />

            <Typography variant="h6" gutterBottom style={{ marginTop: 16 }}>
              {orderDetails.product.name}
            </Typography>

            <Chip label={orderDetails.product.destination} size="small" />

            <div style={{ overflowX: "auto" }}>
              <Table size="small">
                <TableHead>
                <TableRow>
                  <TableCell></TableCell>
                  <TableCell>Pax</TableCell>
                  <TableCell>Valor</TableCell>
                  <TableCell>Total</TableCell>
                  <TableCell>Comissão</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                { orderDetails.options.map((option) => (
                  <TableRow key={option.option.id}>
                    <TableCell>{option.option.name}</TableCell>
                    <TableCell>{option.amount}</TableCell>
                    <TableCell>R$ {formatCurrency(option.price)}</TableCell>
                    <TableCell>R$ {formatCurrency(option.price * option.amount)}</TableCell>
                    <TableCell>R$ {formatCurrency(option.affiliateComission * option.amount)}</TableCell>
                  </TableRow>
                )) }
                </TableBody>
              </Table>
            </div>
          </DialogContent>

          <DialogActions>
            <Button onClick={() => setOrderDetails(null)}>Fechar</Button>
          </DialogActions>
        </Dialog>
      )}
    </>
  );
}
